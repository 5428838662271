import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Events from './pages/Events';
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import NoPage from './pages/NoPage';
import Confirmationpage from "./pages/confirmationpage";
import Detail from "./pages/detail";
import AccountDetail from "./pages/account/Account_Detail";
import Doneren from "./pages/Donation/Donation";
import AddEventWithAuth from "./pages/AddEventWithAuth";
import Admin from "./pages/account/admin/Admin";
import AccountOverzicht from "./pages/account/AccountOverzicht";
import AlgemeneInfo from "./pages/account/algemeneInfo";
import VerlopenEvents from "./pages/account/admin/VerlopenEvents";
import Algemeen from "./pages/account/admin/Algemeen";
import Faq from "./pages/faq";

export default function App() {
    return (
        <Router>
            <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="*" element={<NoPage />} />
                <Route path="events" element={<Events/>} />
                <Route path="detail/:id" element={<Detail/>} />
                <Route path="confirmationpage" element={<Confirmationpage/>} />
                <Route path="add_event" element={<AddEventWithAuth/>} />
                <Route path="account" element={<AccountDetail />}>
                    <Route path="accountOverzicht" element={<AccountOverzicht />} />
                    <Route path="beheren" element={<AlgemeneInfo />} />
                </Route>
                <Route path='doneren' element={<Doneren/>} />
                <Route path="admin" element={<Admin/>}>
                    <Route path="algemeen" element={<Algemeen />} />
                    <Route path="verlopenEvents" element={<VerlopenEvents />} />
                </Route>
                <Route path="faq" element={<Faq />}/>
            </Route>
            </Routes>
        </Router>
    );
}

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import joining_hands from '../../img/faq1.jpg';
import old_people from '../../img/site gift geld.jpg';
import './Donation.css';
import DonationForm from "./donationForm";
import { IoAccessibility } from "react-icons/io5";
import { BiDonateHeart } from "react-icons/bi";
import { AiOutlineDollarCircle } from "react-icons/ai";

const Donation = () => {
    const { isLoading } = useAuth0();

    return (
        <div className="donation-container">
            {isLoading ? (
                <div className="loading-container">
                    <div className="loading-message">Loading...</div>
                </div>
            ) : (
                <>
                    <div className="unauthenticated-container">
                        <div className="donation-banner">
                            <div className="donation-overlay"></div>
                            <img src={joining_hands} alt="Marathon for Charity" className="donation-background-image" />
                            <div className="donation-content">
                                <h1 className="donation-title">Een klein gebaar, een wereld van verschil!</h1>
                                <p className="donation-subtitle">De Hersenletsel Liga vzw is sterk afhankelijk van giften en donaties voor haar werking.</p>
                            </div>
                        </div>
                        <div className='donation-form-overlay'>
                            <div className='donation-qr-code'>
                                <img className='qr-code-image' src='https://hersenletselliga.be/wp-content/uploads/2021/01/Payconiq-QR-code-1.png'></img>
                                <div className='donation-qr-code-content'>
                                    <h2>
                                        Doe een gift
                                    </h2>
                                    <p>
                                        Wij zijn sterk afhankelijk van giften om de levenskwaliteit van personen met een niet-aangeboren hersenletsel en hun naasten te ondersteunen. Elk gebaar helpt!
                                        Scan onze QR-code met Payconiq om eenvoudig en anoniem te doneren, of doe een gift via het formulier hiernaast. Je kan, als je dit verkiest, ook een overschrijving maken naar ons rekeningnummer BE88 0013 2923 3941 met de vermelding “GIFT, je volledige naam en e-mailadres
                                    </p>

                                </div>
                            </div>
                            <div className='donation-form'>
                                <DonationForm maandelijks={true} page={"doneren"} />
                            </div>

                        </div>
                        <div className="Donationimpact-info">
                            <div className="donation-Donationimpact-list">
                                <div className="Donationimpact-items">
                                    <div className="Donationimpact-item">
                                        <div className='Donationimpact-item-title'> <IoAccessibility /></div>
                                        <div className='Donationimpact-item-content'>
                                            <p>Maak elke maand het verschil met een kleine gift. Met  5 euro per maand helpen we verschillende personen verder via onze <em>Hersenletsel lijn</em>.</p>
                                        </div>
                                    </div>
                                    <div className="Donationimpact-item">
                                        <div className='Donationimpact-item-title'> <BiDonateHeart /></div>
                                        <div className='Donationimpact-item-content'>
                                            <p>Elke donatie, klein of groot, maakt een verschil. <strong>Jouw gift helpt ons</strong> bij het ondersteunen van personen met een niet-aangeboren hersenletsel en hun naasten.</p>
                                        </div>
                                    </div>
                                    <div className="Donationimpact-item">
                                        <div className='Donationimpact-item-title'> <AiOutlineDollarCircle /></div>
                                        <div className='Donationimpact-item-content'>
                                            <p>Jouw steun betekent veel voor personen met een hersenletsel.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="donation-page-info">
                                    <div className='donation-page-info-image'>
                                        <img src={old_people} alt="Happy People" className='donation-image-old-people' />
                                    </div>
                                    <div className='donation-page-info-text'>
                                        <p>Voor onze werking zijn we sterk afhankelijk van giften. Een klein gebaar kan een groot effect hebben. Alle beetjes helpen om de levenskwaliteit van personen met een niet-aangeboren hersenletsel, hun familie en mantelzorgers te verbeteren.</p>
                                        <p>Alle giften van minstens 40 € per jaar aan de Hersenletsel Liga geven je recht op een fiscaal attest. Daarvoor hebben we wel je rijksregisternummer nodig.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Donation;

import { CardActionArea, Pagination } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import React, { Component } from 'react';
import {Link, Link as RouterLink} from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import { serverIP } from "../index";
import '../pages/Events.css';
class Events extends Component {
    state = {
        events: [],
        currentPage: 1,
        eventsPerPage: 15,
        searchTerm: '',
    };

    handlePageChange = (event, value) => {
        this.setState({ currentPage: value });
    };
    handleSearchChange = (searchTerm) => {
        this.setState({ searchTerm: searchTerm || '' });
    };

    


    async componentDidMount() {
        try {
            const url = serverIP + '/events';
            const response = await fetch(`${window.location.protocol}//${serverIP}/events`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include' // or 'include' if needed
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const body = await response.json();
            this.setState({ events: body });
        } catch (error) {
            console.error('Error fetching events:', error);
            this.setState({ error: error.message });
        }
    }
    

    render() {
        sessionStorage.removeItem('eventid');
        const { events, currentPage, eventsPerPage, searchTerm } = this.state;
        const sortedEvents = [...events].sort((a, b) => {
            const aDate = new Date(a.startDatum);
            const bDate = new Date(b.startDatum);
            const today = new Date();
          
            const aExpired = aDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
            const bExpired = bDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
          
            if (aExpired && !bExpired) {
              return 1;
            } else if (!aExpired && bExpired) {
              return -1;
            } else {
              return b.totaalBedrag - a.totaalBedrag;
            }
          });

        const filteredEvents = sortedEvents.filter(ev =>
            ev.eventNaam.toLowerCase().includes(searchTerm.toLowerCase())
        );
        
        const indexOfLastEvent = currentPage * eventsPerPage;
        const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
        const currentEvents2 = sortedEvents.slice(indexOfFirstEvent, indexOfLastEvent);
        const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);

        return (
            <div className="App">
                <header className="App-header">
                    <h2 className="eventtitel">Events</h2>

                </header>
                <div className='EventFilter'>
                <FreeSolo events={currentEvents} list={currentEvents2} searchTerm={this.state.searchTerm} onSearchChange={this.handleSearchChange} />

            </div>
                <div className="event-overzicht">
                    {events.length > 0 ? (
                        <>
                            
                            <div className="Eventpost">
                                <div className="events-container">
                                    {currentEvents.map(ev => (
                                        <MediaCard key={ev.id} event={ev}/>
                                    ))}
                                </div>
                            </div>

                            <div className="Pagination">
                                <Pagination
                                    count={Math.ceil(events.length / eventsPerPage)}
                                    page={currentPage}
                                    onChange={this.handlePageChange}
                                    showFirstButton
                                    showLastButton
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </div>

                        </>
                    ) : (
                        <div className="center-container center-containerGeenEvents">
                            <p className="geenEvents">Er zijn geen events gevonden</p>
                            <p className="geenEvents">maak snel een event!</p>
                            <Link to="/add_event" className="button event">Start Je Eigen Fondsenwervingsactie!</Link>
                        </div>

                    )}
                </div>
            </div>
        )
            ;
    }

    Pagination = ({eventsPerPage, totalEvents, paginate}) => {
        const pageNumbers = [];

                for (let i = 1; i <= Math.ceil(totalEvents / eventsPerPage); i++) {

                        pageNumbers.push(i);

                    
                }
                return (
                    <nav>
                        <ul className="pagination">
                            {pageNumbers.map((number) => (
                                <li key={number} className="page-item">
                                    <button onClick={() => paginate(number)} className="page-link">
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                );
                
            };


}

export default Events;

function MediaCard({event}) {
    const imageSource = `${window.location.protocol}//${serverIP}/${event.id}/files/${event.fotoPath[0]}`
    const eventDate = new Date(event.startDatum);
    const today = new Date();
    const isExpired = eventDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
    const progress = Math.min(Math.round((event.totaalBedrag / event.doel) * 100), 100);
    const bedrag = event.totaalBedrag
    const aantaldonaties = event.donations.length
    return (
        <Card>
            <CardActionArea component={RouterLink} to={`/detail/${event.id}`}>
            <CardMedia className="cardMedia"
                image={imageSource}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {event.eventNaam}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {event.thema}<p></p>
                    {event.organisator}
                </Typography>
            </CardContent>
            </CardActionArea>
            <CardActions>
                <div className='cardactionsContainer'>
                    <div className='infoContainer'>
                        <p> {aantaldonaties} donateurs</p>
                        <p id="info-container-bedrag"> € {bedrag}</p>
                    </div>
                    <div>
                        {isExpired && <span style={{ color: "red" }}>Verlopen</span>}
                    </div>
                </div>
            
            </CardActions>    


        </Card>

    );
}
function FreeSolo({ events, list, searchTerm, onSearchChange }) {
    const handleChange = (event, newValue) => {
        onSearchChange(newValue || '');
    };

    return (
        <Stack spacing={2} sx={{ width: 300 }}>
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                enableClearable
                options={list.map((option) => option.eventNaam)}
                value={searchTerm}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Zoek acties..."
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                )}
            />
        </Stack>
    );
}


import configJson from "./config.json";

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
    configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
      ? configJson.audience
      : null;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
    scope: configJson.scope,
    serverUrl: configJson.serverUrl,
    websiteUrl: configJson.websiteUrl,
    cacheLocation: configJson.cacheLocation,
    useRefreshTokens: configJson.useRefreshTokens,
    stripe_keuze_bedrag:configJson.stripe_keuze_bedrag,
    stripe_maandelijks_4:configJson.stripe_maandelijks_4,
    stripe_maandelijks_5:configJson.stripe_maandelijks_5,
    stripe_maandelijks_10:configJson.stripe_maandelijks_10,
    stripe_maandelijks_15:configJson.stripe_maandelijks_15,
    emailjs_serviceID: configJson.emailjs_serviceID,
    emailjs_publicKey: configJson.emailjs_publicKey,
    emailjs_temlapteID_add_event: configJson.emailjs_templateID_add_event,
    emailjs_temlapteID_delete_event: configJson.emailjs_templateID_delete_event,
  };
}

import React, {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Button, Col, Container, Row, Table} from 'reactstrap';
import {serverIP} from "../../../index";
import {Link, Route, Routes, useLocation} from 'react-router-dom';
import './Admin.css';
import Algemeen from "./Algemeen";
import VerlopenEvents from "./VerlopenEvents";

export const Admin = () => {
    const { user, isAuthenticated } = useAuth0();
    const [role, setRole] = useState();
    const location = useLocation();
    const getUserRoles = async () => {
        setRole(user["http://localhost/roles"]?.includes("Admin"))
    }

    useEffect(() => {
        if(isAuthenticated){
            getUserRoles();
        }
    }, [user, isAuthenticated, role]);

    const isCurrentPath = (path) => location.pathname === path;

    return (

        <div className='admin-body'>
        <div className="admin-container">
            <div className="headerAdmin">
                <div className="admin-details-row">
                    <div className='admin-picture' xs={12} md={4}>
                        <img src={user?.picture} alt="Profile" className="rounded-circle img-fluid profile-picture mb-3 mb-md-0" />
                    </div>
                    <div className='admin-name' xs={12} md={8}>
                        <p className="adminTitle">{user?.name}</p>
                        <p className='admin-title-title-container'>Admin</p>
                    </div>
                </div>
                <div className='admin-row'>
                    <div className="accountOverzicht">
                        {!isCurrentPath('/admin/algemeen') && (
                            <Link to={'/admin/algemeen'}>
                                <Button className="algemeenButton">Algemeen</Button>
                            </Link>
                        )}
                        {!isCurrentPath('/admin/verlopenEvents') && (
                            <Link to={'/admin/verlopenEvents'}>
                                <Button className="VerlopenEventsButton">Verlopen events</Button>
                            </Link>
                        )}
                        {!isCurrentPath('/account/accountOverzicht') && (
                            <Link to={'/account/accountOverzicht'}>
                                <Button className="adminPaginaButton">Account overzicht</Button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <Routes className="route-container">
                <Route path="algemeen" element={<Algemeen/>}/>
                <Route path="verlopenEvents" element={<VerlopenEvents/>}/>
            </Routes>
        </div >
        </div>
    );
};




export default Admin;


import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import DonationLinkButton from "./DonationLinkButton";
import { config } from "../../index";
import './donationForm.css';

const DonationForm = ({ maandelijks, eventid, page }) => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [updateSuccess, setUpdateSuccess] = useState(false); // State to track update success
    const [Bedrijf, setBedrijf] = useState(false);
    const [tempBedrijf, setTempBedrijf] = useState(false); // Temporary state to hold the choice until submission
    const [showMaandelijks, setShowMaandelijks] = useState(false);
    const [showName, setShowName] = useState(false);
    const [donationName, setDonationName] = useState("");
    const [donationNameError, setDonationNameError] = useState(false);

    const [formData, setFormData] = useState({
        voornaam: '',
        familienaam: '',
        rijksregisternummer: '',
        straatEnHuisnummer: '',
        postcode: '',
        stad: '',
        land: '',
        bedrijfsnaam: '',
        ondernemingsnummer: '',
    });
    const [errors, setErrors] = useState({});

    const getManagementApiToken = async () => {
        try {
            return await getAccessTokenSilently();
        } catch (error) {
            console.error("Error getting management API token:", error);
        }
    };

    const updateUserMetadata = async (userId, metadata) => {
        const token = await getManagementApiToken();
        try {
            if (Bedrijf !== null) {
                metadata.bedrijf = Bedrijf;
            }
            const response = await fetch(`${config.audience}users/${userId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_metadata: metadata }),
            });
            await response.json();
            if (formData.voornaam !== '' && formData.familienaam !== '' && formData.rijksregisternummer !== '' && formData.stad !== '' && formData.straatEnHuisnummer !== '' && formData.postcode !== '' && formData.land !== '') {
                setUpdateSuccess(true);
            }
        } catch (error) {
            console.error("Error updating user metadata:", error);
            setUpdateSuccess(false);
        }
    };

    const fetchUserMetadata = async () => {
        const token = await getManagementApiToken();
        if (!token) return;

        try {
            const response = await fetch(`${config.audience}users/${user.sub}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const { user_metadata } = await response.json();
            if (user_metadata.bedrijf !== undefined) {
                setBedrijf(user_metadata.bedrijf);
                setTempBedrijf(user_metadata.bedrijf);
            } else {
                setBedrijf(null);
            }
            setFormData(prevState => ({
                ...prevState,
                ...user_metadata
            }));
            if (user_metadata.voornaam !== '' && user_metadata.familienaam !== '' && user_metadata.rijksregisternummer !== '' && user_metadata.stad !== '' && user_metadata.straatEnHuisnummer !== '' && user_metadata.postcode !== '' && user_metadata.land !== '') {
                setUpdateSuccess(true);
            }
        } catch (error) {
            console.error("Error fetching user metadata:", error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserMetadata();
        }
    }, [isAuthenticated]);

    const formatRijksregisternummer = (value) => {
        const numeric = value.replace(/[^\d]/g, '');
        let formatted = '';
        if (numeric.length > 0) formatted = `${numeric.slice(0, 2)}`;
        if (numeric.length > 2) formatted += `.${numeric.slice(2, 4)}`;
        if (numeric.length > 4) formatted += `.${numeric.slice(4, 6)}`;
        if (numeric.length > 6) formatted += `-${numeric.slice(6, 9)}`;
        if (numeric.length > 9) formatted += `.${numeric.slice(9, 11)}`;
        return formatted;
    };

    const validateRijksregisternummer = (num) => {
        if (num.length === 0) return true;

        const rrn = num.replace(/[^0-9]/g, '');
        if (rrn.length !== 11) return false;

        const rrnChecksum = parseInt(rrn.substring(9, 11), 10);
        let partToCalculate = rrn.substring(0, 9);
        let rrnInt = parseInt(partToCalculate, 10);
        let checksum = 97 - (rrnInt % 97);

        if (rrnChecksum === checksum) {
            return true;
        }

        partToCalculate = "2" + partToCalculate;
        rrnInt = parseInt(partToCalculate, 10);
        checksum = 97 - (rrnInt % 97);

        return rrnChecksum === checksum;
    };

    const validatePostcode = (value) => {
        if (value.length === 0) return true;
        return value.length === 4;
    };

    const ValidateOndernemingsnummer = (num) => {
        if (num.length === 0) return true;

        const cleaned = num.replace(/[^0-9]/g, '');
        if (cleaned.length !== 10) {
            return false;
        }

        const baseNumber = cleaned.substring(0, 8);
        const checkDigits = parseInt(cleaned.substring(8, 10), 10);
        const resultOfDivision = Math.floor(parseInt(baseNumber, 10) / 97);
        const product = resultOfDivision * 97;
        const calculatedDifference = parseInt(baseNumber, 10) - product;

        return checkDigits === (97 - calculatedDifference);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let formattedValue = value;

        if (name === 'rijksregisternummer') {
            formattedValue = formatRijksregisternummer(value);
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));

        if (name === 'rijksregisternummer') {
            const isValid = validateRijksregisternummer(formattedValue);
            setErrors(prev => ({ ...prev, rijksregisternummer: isValid ? '' : 'Ongeldig rijksregisternummer' }));
        }
        if (name === 'postcode') {
            const isValid = validatePostcode(value);
            setErrors(prev => ({ ...prev, postcode: isValid ? '' : 'Ongeldige postcode' }));
        }
        if (name === 'ondernemingsnummer') {
            const isValid = ValidateOndernemingsnummer(value);
            setErrors(prev => ({ ...prev, ondernemingsnummer: isValid ? '' : 'Ongeldig ondernemingsnummer' }));
        }
        if (formData.voornaam !== '' && formData.familienaam !== '' && formData.rijksregisternummer !== '' && formData.stad !== '' && formData.straatEnHuisnummer !== '' && formData.postcode !== '' && formData.land !== '') {
            setUpdateSuccess(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (Object.values(errors).every(e => e === '')) {
            if (tempBedrijf !== null && Bedrijf === null) {
                setBedrijf(tempBedrijf);
                await updateUserMetadata(user.sub, { ...formData, bedrijf: tempBedrijf });
            } else {
                await updateUserMetadata(user.sub, formData);
            }
        } else {
            alert('Please correct the errors before submitting.');
        }
    };

    const kiesBedrijf = (e, isBedrijf) => {
        e.preventDefault();
        setTempBedrijf(isBedrijf);
        document.getElementById("welBedrijf").classList.add("notselected");
        document.getElementById("geenBedrijf").classList.add("notselected");
        e.target.classList.remove("notselected");
    };

    const login = () => {
        loginWithRedirect({
            audience: `${config.audience}`,
            scope: `${config.scope}`,
            redirect_uri: `http://localhost:3000/${page}`
        });
    };

    const changeDonationName = (e) => {
        setDonationName(e.target.value);
        setDonationNameError(false);
    };

    const checkDonationName = () => {
        if (showName) {
            const check = donationName.trim() !== "";
            setDonationName(donationName.trim());
            setDonationNameError(!check);
            return check;
        }
        return true;
    };

    const showNameChange = (e) => {
        setShowName(e.target.checked);
        setDonationNameError(false);
    };

    return (
        <div className='donation-form-content'>
            {isAuthenticated ? (
                <form onSubmit={handleSubmit} className="metadata-form-donate">
                    <div className="bedrijf-container">
                        {Bedrijf === null && (
                            <>
                                <button id="geenBedrijf" onClick={(e) => kiesBedrijf(e, false)}>Persoonlijke donatie</button>
                                <button className="notselected" id="welBedrijf" onClick={(e) => kiesBedrijf(e, true)}>Bedrijfs donatie</button>
                            </>
                        )}
                    </div>
                    {tempBedrijf && (
                        <div className="form-group">
                            <label>Bedrijfsnaam:</label>
                            <input
                                type="text"
                                name="bedrijfsnaam"
                                value={formData.bedrijfsnaam}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    )}

                    <div className='title-container'>
                        <h2>
                            Vul hier uw gegevens in:
                        </h2>
                    </div>

                    <div className="form-group">
                        <input placeholder='Voornaam' type="text" name="voornaam" value={formData.voornaam} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <input placeholder='Familienaam' type="text" name="familienaam" value={formData.familienaam} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <input placeholder='Straat en huisnummer' type="text" name="straatEnHuisnummer" value={formData.straatEnHuisnummer} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <input placeholder='Postcode' type="text" name="postcode" value={formData.postcode} onChange={handleChange} />
                        {errors.postcode && <div className="error">{errors.postcode}</div>}
                    </div>
                    <div className="form-group">
                        <input placeholder='Stad' type="text" name="stad" value={formData.stad} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <input placeholder='Land' type="text" name="land" value={formData.land} onChange={handleChange} />
                    </div>
                    {
                        !tempBedrijf && (
                            <div className="form-group">
        
                                <input placeholder='Rijksregisternummer' type="text" name="rijksregisternummer" value={formData.rijksregisternummer} onChange={handleChange} />
                                {errors.rijksregisternummer && <div className="error">{errors.rijksregisternummer}</div>}
                            </div>
                        )
                    }

                    {tempBedrijf && (
                        <div className="form-group">
                            <label>Ondernemingsnummer:</label>
                            <input
                                type="text"
                                name="ondernemingsnummer"
                                value={formData.ondernemingsnummer}
                                onChange={handleChange}
                                required
                            />
                            {errors.ondernemingsnummer && <div className="error">{errors.ondernemingsnummer}</div>}
                        </div>
                    )}
                    {!maandelijks &&
                        <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                            <input id="show_name" type="checkbox" name="show_name" onChange={showNameChange} style={{ marginRight: '0.5em', width: 'auto', height: 'auto' }} />
                            <label htmlFor="show_name">Naam tonen op event pagina</label>
                        </div>
                    }
                    {showName &&
                        <div className="form-group">
                            <label>Zichtbare naam:</label>
                            <input type="text" name="donation_name" value={donationName} onChange={changeDonationName} />
                        </div>
                    }
                    {donationNameError && <div className="error">Gelieve de zichtbare naam in te vullen</div>}
                    
                    <div className='buttons'>
                        {!showMaandelijks ? (
                            <>
                                <DonationLinkButton soort="anoniem" buttonText="Doneer anoniem" eventid={eventid} />
                                <DonationLinkButton soort="doneren" buttonText="Doneer" userid={user?.sub} eventid={eventid} visible_name={donationName} customCheck={checkDonationName} />
                                {maandelijks && (<button className='donation-form-button' onClick={() => setShowMaandelijks(true)}>Maandelijkse donatie</button>)}
                            </>
                        ) : (
                            <div className='donation-maandelijks-container'>
                                <button className="notselected" onClick={() => setShowMaandelijks(false)}>Terug</button>
                                <p>Maandelijkse betaling van:</p>
                                <div className="monthly-buttons">
                                    <DonationLinkButton className = "monthly-button" soort="4" buttonText="€4" userid={user?.sub} />
                                    <DonationLinkButton className = "monthly-button" soort="5" buttonText="€5" userid={user?.sub} />
                                    <DonationLinkButton className = "monthly-button" soort="10" buttonText="€10" userid={user?.sub} />
                                    <DonationLinkButton className = "monthly-button" soort="15" buttonText="€15" userid={user?.sub} />
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div className='nodig'>
                        <p style={{ fontStyle: 'italic', textAlign: 'center' }}>Alle informatie is nodig indien een fiscaal attest gewenst is.</p>
                    </div>
                </form>
            ) : (
                <div className="metadata-form">
                    <div className="buttons">
                        <button onClick={login}>Log in</button>
                        <DonationLinkButton soort="anoniem" buttonText="Anonieme donatie" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DonationForm;

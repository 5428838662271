import React from "react";
import "./SimpleModal.css";

const SimpleModal = ({ isOpen, close, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {children}
            </div>
        </div>
    );
};

export default SimpleModal;

import {serverIP} from "../../../index";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import {Button, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import './VerlopenEvents.css';

export const VerlopenEvents = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [role, setRole] = useState();
    const [expiredEvents, setExpiredEvents] = useState([]);
    const [showDeleteReason, setShowDeleteReason] = useState(false)
    const navigate = useNavigate()

    const getUserRoles = async () => {
        setRole(user["http://localhost/roles"]?.includes("Admin"))
    }

    useEffect(() => {
        if(isAuthenticated){
            getUserRoles();
            if(role) {
                fetchUnacceptedEvents()
            }
        }
    }, [user, isAuthenticated, role]);

    const fetchUnacceptedEvents = async () => {
        try {
            //response bevat alle events
            const response = await fetch(`${window.location.protocol}//${serverIP}/events`, { method: "GET" });
            const allEvents = await response.json();

            //filter out all expiredEvents
            const expiredEvents = allEvents.filter(ev => {
                const eventDate = new Date(ev.eindDatum);
                const today = new Date();
                return eventDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
            });

            //sorteer evennts => minst opgehaald eerst, dan het langs verlopen
            const sortedEvents = expiredEvents.sort((a, b) => {
                const totalDonationsA = a.totaalBedrag;
                const totalDonationsB = b.totaalBedrag;

                if (totalDonationsA !== totalDonationsB) {
                    return totalDonationsA - totalDonationsB;
                } else {
                    // If total donations are equal, sort by expiration date (longest expired events first)
                    const startDateA = new Date(a.startDatum).getTime();
                    const startDateB = new Date(b.startDatum).getTime();
                    const today = new Date().getTime();

                    const expiredTimeA = today - startDateA;
                    const expiredTimeB = today - startDateB;

                    return expiredTimeB - expiredTimeA;
                }
            });
            setExpiredEvents(sortedEvents);
        } catch (e) {
            console.log(e);
        }
    };

    const handleRowClick = (eventId) => {
        navigate(`/detail/${eventId}`);
    };

    const calculateDaysDifference = (endDate) => {
        const today = new Date();
        const end = new Date(endDate);
        const differenceInTime =  today.getTime() - end.getTime();
        return Math.floor(differenceInTime / (1000 * 3600 * 24));
    };

    const rejectEvent = async (event) => {
        try {
            setShowDeleteReason(false)
            const token = await getAccessTokenSilently()
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/${event.id}`, {
                method: 'DELETE',
                headers:{
                    authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ accepted: false }), // Depending on how your backend is set up, this may not be necessary
            });
            if (response.status === 204) {
                console.log("Event successfully deleted.");
                // Update the events list to reflect the change
                await fetchUnacceptedEvents()
            } else {
                console.error("Failed to delete event. Status:", response.status);
            }
        } catch (e) {
            console.error("Error deleting event:", e);
        }
    };

    return (
        <div className="verlopen-events-container">
            {role && (
                <div className="bodyAdmin">
                    <div>
                        <div>
                            <div>
                                <h2 className="align-right-verlopen-events">Verlopen events</h2>
                                {expiredEvents.length === 0 ? (
                                    <div className="centered-message">
                                        <p className="melding">Geen verlopen events <FontAwesomeIcon
                                            className="meldingCheck" icon={faCheck}/></p>
                                    </div>
                                ) : (
                                    <div striped className="table">
                                        <thead>
                                        <tr>
                                            <th>Ingezamelde bedrag</th>
                                            <th>Eind datum</th>
                                            <th>Aantal dagen verlopen</th>
                                            <th>Verwijderen</th>
                                        </tr>
                                        </thead>
                                        <tbody className="bodyVerlopenEvents">
                                        {expiredEvents.map((event, index) => (
                                            <React.Fragment key={event.id}>
                                                <tr className="rowStyle">
                                                    <td className="textTd" onClick={() => handleRowClick(event.id)}>
                                                        {`${event.totaalBedrag}`}
                                                    </td>
                                                    <td className="textTd" onClick={() => handleRowClick(event.id)}>
                                                        {`${event.eindDatum}`}
                                                    </td>
                                                    <td className="textTd" onClick={() => handleRowClick(event.id)}>
                                                        {calculateDaysDifference(event.eindDatum)}
                                                    </td>
                                                    <td className="icon">
                                                        <FontAwesomeIcon icon={faTimesCircle} className="cancel-iconVerlopen" onClick={() => setShowDeleteReason(event.id)} />
                                                    </td>
                                                </tr>
                                                {showDeleteReason === event.id && (
                                                    <td colSpan="4">
                                                        <div className="buttonContainerVerlopen">
                                                            <Button className="exporteren" onClick={() => setShowDeleteReason(null)}>Niet verwijderen</Button>
                                                            <Button className="exporterenTest" onClick={() => rejectEvent(event)}>Verwijderen</Button>
                                                        </div>
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        ))}
                                        </tbody>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

};

export default VerlopenEvents
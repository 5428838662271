import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { serverIP } from "../../index";
import {config} from "../../index";

const useAccountDetailLogic = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [rrnr, setRrnr] = useState('');
    const [formData, setFormData] = useState({});
    const [placeholders, setPlaceholders] = useState({});
    const [events, setEvents] = useState([]);
    const [donations, setDonations] = useState([]);

    const getManagementApiToken = async () => {
        try {
            return await getAccessTokenSilently();
        } catch (error) {
            console.error("Error getting management API token:", error);
        }
    };

    const updateUserMetadata = async (userId) => {
        const token = await getManagementApiToken();
        try {
            const response = await fetch(`${config.audience}users/${userId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_metadata: { RRNR: rrnr } }),
            });
            await response.json();
        } catch (error) {
            console.error("Error updating user metadata:", error);
        }
    };

    const getUserMetadata = async () => {
        const token = await getManagementApiToken();
        try {
            const response = await fetch(`${config.audience}users/${user.sub}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setFormData(data.user_metadata);
            setPlaceholders(data.user_metadata);
        } catch (error) {
            console.error("Error fetching user metadata:", error);
        }
    };

    const fetchEventsOfAccount = async () => {
        try {
            const response = await fetch(`${window.location.protocol}//${serverIP}/events`, { method: "GET" });
            const body = await response.json();
            const userEvents = body.filter(event => event.userSub === user.sub);
            setEvents(userEvents);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    }

    const fetchDonationsOfAccount = async (userId) => {
        try {
            const token = await getAccessTokenSilently()
            const response = await fetch(`${window.location.protocol}//${serverIP}/donation/history/${userId.replace("|","%7C")}`, { method: "GET" , headers: {authorization: `Bearer ${token}`}});
            const body = await response.json();
            setDonations(body);
        } catch (error) {
            console.error("Error fetching donations:", error);
        }
    };

    const fetchFiscaalAttest = async () => {
        console.log('Fetching fiscal attest...');
    }

    const calculateTotalDonations = (donations) => {
        return donations.reduce((total, donation) => total + parseFloat(donation.amount), 0);
    };

    useEffect(() => {
        if (isAuthenticated) {
            getUserMetadata();
            fetchEventsOfAccount();
            fetchDonationsOfAccount(user.sub);
        }
    }, [user?.sub, isAuthenticated]);

    return {
        user,
        isAuthenticated,
        rrnr,
        setRrnr,
        events,
        donations,
        updateUserMetadata,
        fetchEventsOfAccount,
        fetchDonationsOfAccount,
        calculateTotalDonations,
        fetchFiscaalAttest,
        formData,
        setFormData,
        placeholders,
        updateFormMetadata: updateUserMetadata
    };
};

export default useAccountDetailLogic;

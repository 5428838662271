import {config} from "../../index";
import './donationForm.css';

const DonationLinkButton = ({soort, eventid, userid, buttonText, customCheck, visible_name}) => {
    const stripeUrls = {
        doneren:config.stripe_keuze_bedrag,
        anoniem:config.stripe_keuze_bedrag,
        4:config.stripe_maandelijks_4,
        5:config.stripe_maandelijks_5,
        10:config.stripe_maandelijks_10,
        15:config.stripe_maandelijks_15
    }

    const handleClick = () => {
        if(customCheck && !customCheck()){
            return;//fails
        }
        if(eventid)
            sessionStorage.setItem('eventid', eventid)
        if(userid)
            userid = userid.replace("|","_")
        let referenceId = eventid ? `${eventid}__${userid || ''}__` : `__${userid || ''}__`;
        if(visible_name){
            referenceId = referenceId+`${visible_name}`
        }
        window.location.href = `${stripeUrls[soort]}?client_reference_id=${referenceId}`;
    }


    return(
        <button className="donation-form-button" onClick={handleClick}>{buttonText}</button>
    )
}

export default DonationLinkButton
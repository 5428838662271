import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import {config} from "../../index";

const AlgemeneInfo = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [updateSuccess, setUpdateSuccess] = useState(false); // State to track update success
    const [Bedrijf, setBedrijf] = useState(false);
    const [tempBedrijf, setTempBedrijf] = useState(false); // Temporary state to hold the choice until submission


    const [formData, setFormData] = useState({
        voornaam: '',
        familienaam: '',
        rijksregisternummer: '',
        straatEnHuisnummer: '',
        postcode: '',
        stad: '',
        land: '',
        bedrijfsnaam: '',
        ondernemingsnummer: '',
    });
    const [errors, setErrors] = useState({});
    const getManagementApiToken = async () => {
        try {
            return await getAccessTokenSilently();
        } catch (error) {
            console.error("Error getting management API token:", error);
        }
    };


    const updateUserMetadata = async (userId, metadata) => {
        const token = await getManagementApiToken();
        try {
            if (Bedrijf !== null) {
                metadata.bedrijf = Bedrijf;
            }
            const response = await fetch(`${config.audience}users/${userId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_metadata: metadata }),
            });
            await response.json();
            setUpdateSuccess(true);
        } catch (error) {
            console.error("Error updating user metadata:", error);
            setUpdateSuccess(false);
        }
    };

    const fetchUserMetadata = async () => {
        const token = await getManagementApiToken();
        if (!token) return;

        try {
            const response = await fetch(`${config.audience}users/${user.sub}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const { user_metadata } = await response.json();
            if (user_metadata.bedrijf !== undefined) {
                setBedrijf(user_metadata.bedrijf);
                setTempBedrijf(user_metadata.bedrijf);
            } else {
                setBedrijf(null);
            }
            setFormData(prevState => ({
                ...prevState,
                ...user_metadata
            }));
            if (user_metadata.voornaam !== '' && user_metadata.familienaam !== '' && user_metadata.rijksregisternummer !== '' && user_metadata.stad !== '' && user_metadata.straatEnHuisnummer !== '' && user_metadata.postcode !== '' && user_metadata.land !== '') {
                console.log("true")
            }
        } catch (error) {
            console.error("Error fetching user metadata:", error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserMetadata();
        }
    }, [isAuthenticated]);

    const formatRijksregisternummer = (value) => {
        const numeric = value.replace(/[^\d]/g, '');
        let formatted = '';
        if (numeric.length > 0) formatted = `${numeric.slice(0, 2)}`;
        if (numeric.length > 2) formatted += `.${numeric.slice(2, 4)}`;
        if (numeric.length > 4) formatted += `.${numeric.slice(4, 6)}`;
        if (numeric.length > 6) formatted += `-${numeric.slice(6, 9)}`;
        if (numeric.length > 9) formatted += `.${numeric.slice(9, 11)}`;
        return formatted;
    };

    const validateRijksregisternummer = (num) => {
        const rrn = num.replace(/[^0-9]/g, '');
        if (rrn.length !== 11) return false;

        // Extract the checksum part of the RRN
        const rrnChecksum = parseInt(rrn.substring(9, 11), 10);

        // We pick the RRN part we want to recalculate the checksum for
        let partToCalculate = rrn.substring(0, 9);
        let rrnInt = parseInt(partToCalculate, 10);

        // We calculate the expected checksum
        let checksum = 97 - (rrnInt % 97);

        // We compare the existing checksum with the calculated
        if (rrnChecksum === checksum) {
            // We have a good checksum
            return true;
        }

        // Checksum not yet ok. We check for a possible 1900/2000 situation;

        // We repeat the same test but now with the extra '2' added to the part
        partToCalculate = "2" + partToCalculate;
        rrnInt = parseInt(partToCalculate, 10);

        // We calculate the expected checksum, again
        checksum = 97 - (rrnInt % 97);

        // We compare the existing checksum with the calculated, again
        return rrnChecksum === checksum;

    };

    const validatePostcode = (value) => {
        return value.length === 4;
    };

    const ValidateOndernemingsnummer = (num) => {
        const cleaned = num.replace(/[^0-9]/g, '');

        // Check the length is exactly 10 digits
        if (cleaned.length !== 10) {
            return false;
        }

        // Split into base number and check digits
        const baseNumber = cleaned.substring(0, 8);
        const checkDigits = parseInt(cleaned.substring(8, 10), 10);

        // Compute the integer result of dividing the base number by 97
        const resultOfDivision = Math.floor(parseInt(baseNumber, 10) / 97);

        // Multiply the result by 97
        const product = resultOfDivision * 97;

        // Subtract the product from the base number
        const calculatedDifference = parseInt(baseNumber, 10) - product;

        // Check if the calculated difference matches the check digits
        return checkDigits === (97 - calculatedDifference)
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let formattedValue = value;

        if (name === 'rijksregisternummer') {
            formattedValue = formatRijksregisternummer(value);
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));

        if (name === 'rijksregisternummer') {
            const isValid = validateRijksregisternummer(formattedValue);
            setErrors(prev => ({ ...prev, rijksregisternummer: isValid ? '' : 'Ongeldig rijksregisternummer' }));
        }
        if (name === 'postcode') {
            const isValid = validatePostcode(value);
            setErrors(prev => ({ ...prev, postcode: isValid ? '' : 'Ongeldige postcode' }));
        }
        if (name === 'ondernemingsnummer') {
            const isValid = ValidateOndernemingsnummer(value);
            setErrors(prev => ({ ...prev, ondernemingsnummer: isValid ? '' : 'Ongeldig ondernemingsnummer' }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (Object.values(errors).every(e => e === '')) {
            if (tempBedrijf !== null && Bedrijf === null) {
                setBedrijf(tempBedrijf);
                await updateUserMetadata(user.sub, { ...formData, bedrijf: tempBedrijf });
            } else {
                await updateUserMetadata(user.sub, formData);
            }
        } else {
            alert('Please correct the errors before submitting.');
        }
    };

    const kiesBedrijf = (e, isBedrijf) => {
        e.preventDefault();
        setTempBedrijf(isBedrijf);
        document.getElementById("welBedrijf").classList.add("notselected");
        document.getElementById("geenBedrijf").classList.add("notselected");
        e.target.classList.remove("notselected");
    }

    return (
        <div>
            {isAuthenticated && (
                <form onSubmit={handleSubmit} className="metadata-form">
                    <div className="buttons">
                        {Bedrijf === null && (
                            <>
                                <button id="geenBedrijf" onClick={(e) => kiesBedrijf(e, false)}>Persoonlijke donatie</button>
                                <button className="notselected" id="welBedrijf" onClick={(e) => kiesBedrijf(e, true)}>Bedrijfs donatie</button>
                            </>
                        )}
                    </div>

                    <div className="form-group">
                        <label>Email:</label>
                        <input type="text" name="email" value={user?.email} onChange={handleChange} disabled={true} />
                    </div>

                    {tempBedrijf && (
                        <div className="form-group">
                            <label>Bedrijfsnaam:</label>
                            <input
                                type="text"
                                name="bedrijfsnaam"
                                value={formData.bedrijfsnaam}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    )}

                    <div className="form-group">
                        <label>Voornaam:</label>
                        <input type="text" name="voornaam" value={formData.voornaam} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Familienaam:</label>
                        <input type="text" name="familienaam" value={formData.familienaam} onChange={handleChange} />
                    </div>

                    <div className="form-group">
                        <label>Straat en Huisnummer:</label>
                        <input type="text" name="straatEnHuisnummer" value={formData.straatEnHuisnummer} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Postcode:</label>
                        <input type="text" name="postcode" value={formData.postcode} onChange={handleChange} />
                        {errors.postcode && <div className="error">{errors.postcode}</div>}
                    </div>
                    <div className="form-group">
                        <label>Stad:</label>
                        <input type="text" name="stad" value={formData.stad} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Land:</label>
                        <input type="text" name="land" value={formData.land} onChange={handleChange} />
                    </div>
                    {
                        !tempBedrijf && (
                            <div className="form-group">
                                <label>Rijksregisternummer:</label>
                                <input type="text" name="rijksregisternummer" value={formData.rijksregisternummer} onChange={handleChange} />
                                {errors.rijksregisternummer && <div className="error">{errors.rijksregisternummer}</div>}
                            </div>
                        )
                    }

                    {tempBedrijf && (
                        <div className="form-group">
                            <label>Ondernemingsnummer:</label>
                            <input
                                type="text"
                                name="ondernemingsnummer"
                                value={formData.ondernemingsnummer}
                                onChange={handleChange}
                                required
                            />
                            {errors.ondernemingsnummer && <div className="error">{errors.ondernemingsnummer}</div>}
                        </div>
                    )}

                    <button type="submit">Opslaan</button>

                    <div className='nodig'>
                        <p style={{ fontStyle: 'italic', textAlign: 'center' }}>Alle informatie is nodig indien een fiscaal attest gewenst is.</p>
                    </div>

                    {updateSuccess && (
                        <div className="success">
                            <p>Informatie opgeslagen</p>
                        </div>
                    )}

                </form>
            )}
        </div >


    )
}

export default AlgemeneInfo
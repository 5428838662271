import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { AccountOverzicht } from './AccountOverzicht';
import authUtils from './authUtils';
import AlgemeneInfo from './algemeneInfo';
import './Account_Detail.css';


export const AccountDetailComponent = () => {
    const { user } = authUtils();
    const location = useLocation();

    const isCurrentPath = (path) => location.pathname === path;

    /*
    const printUser = async () => {
        console.log('test', getUserMetaData);
    };
    */

    return (
        <div className='account-body'>
        <div className="account-container">
            <div className='profile-details-container'>
               
                    {!isCurrentPath('/account/accountOverzicht') && (
                        <div className="centered-button-container">
                                <Link to={'/account/accountOverzicht'}>
                                    <Button>Account overzicht</Button>
                                </Link>
                                </div>
                            )}
                        {!isCurrentPath('/account/beheren') && (
                            <div className="profile-container">
                                <div className='profile-header-container'>
                                    <img
                                        src={user?.picture}
                                        alt="Profile"
                                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                                    />
                                    <div className='profile-name-container'>
                                        <p>{user?.name}</p>
                                        <div className='email-container'>
                                            <p className="lead text-muted">{user?.email}</p>
                                        </div>
                                    </div> 
                                </div>
                                <div className='profile-content-container' md={8}>
                                    <div className='profile-content-row'>
                                        <p>{user?.birthdate}</p>
                                    </div>
                                </div>
                                <div className='account-button-container'>
                                    <Link className="beheerAccountButton" to={'/account/beheren'}>
                                        <div className='beheer-button'>Beheer accountgegevens</div>
                                    </Link>
                                </div>
                            </div>
                        )}
                </div>
            <Routes className = 'route-container'>
                <Route path="accountOverzicht" element={<AccountOverzicht/>}/>
                <Route path="beheren" element={<AlgemeneInfo/>}/>
            </Routes>
        </div>
        </div>
    );
};

export default AccountDetailComponent;
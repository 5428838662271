import React from 'react';
import { Link } from 'react-router-dom';
import joining_hands from '../img/background2.jpg';
import lightbrain from '../img/over NAH.jpg'
import '../pages/Home.css';


function Home() {
    return (
        <div className="home-component">
            <div className="home">
                <img src={joining_hands} alt="Marathon for Charity" className="background-image" />
                {/* <div className="home-content">
                    <div className="text-box">
                        <h1 className="title">Een kleine gift een groot verschil!</h1>
                        <p className="subtitle">De Hersenletsel Liga vzw put al haar inkomsten uit giften en lidmaatschapsgelden.</p
                    </div>

                </div> */}
            </div>


            <div className='donation-info'>
                <div className="buttons">
                    <Link to="/doneren" className="button donate">Doneer Nu - Elke Euro Telt!</Link>
                    <Link to="/events" className="button donate">Steun Een Event!</Link>
                    <Link to="/add_event" className="button event">Start Je Eigen Event!</Link>
                </div>
                <div className='inception-donation-info-text'>
                    <div className='donation-info-text'>
                        <img src={lightbrain} alt="Marathon for Charity" className="small-image" />
                        <p>De <a href='https://hersenletselliga.be/' target='_blank'><strong>Hersenletsel Liga</strong></a> wil de levenskwaliteit van personen met een niet-aangeboren hersenletsel (NAH) verbeteren.
                            <br></br><br></br>
                        Een klein gebaar kan een groot verschil maken voor personen met een hersenletsel, hun familie en mantelzorgers.
                        </p>
                    </div>
                    <div className='donation-info-text'>
                        <p>
                            <h3>Je kan ons steunen op verschillende manieren:</h3>
                            <ul>
                            	<li>Je kan een éénmalige gift doen of kiezen voor een maandelijkse donatie.</li>
                            	<li>Je kan deelnemen aan een event om ons te steunen.</li>
                            	<li>Je kan zelf een event organiseren om onze werking te ondersteunen.</li>
                            </ul>
                            Alle giften van minstens 40 € aan de Hersenletsel Liga vzw geven je recht op een fiscaal attest.
                        </p>
                    </div>
                </div>
                <div class="donation-impact-list">
                    <h3><strong>Wat we doen met jouw gift:</strong></h3>
                    <div class="impact-items">
                        <div class="impact-item">
                            <p>De liga helpt mensen mee op weg in hun zoektocht naar <strong>zinvolle dagbesteding</strong>. Dat kan gaan van vrijwilligerswerk, aangepaste hobbies, sporten op maat, enz.</p>
                        </div>
                        <div class="impact-item">
                            <p>Via de <a href='https://hersenletsellijn.be/' target='_blank<'><strong>Hersenletsel Lijn</strong></a> en thuis-bezoeken proberen we antwoord te bieden op elke vraag of bezorgdheid van personen met een hersenletsel of hun mantelzorger.</p>
                        </div>
                        <div class="impact-item">
                            <p>We <strong><a href="https://hersenletselliga.be/activiteiten/" target='_blank'>ondersteunen activiteiten</a></strong> voor personen met een hersenletsel en hun naasten.</p>
                        </div>
                        <div class="impact-item">
                            <p>Onze <strong><a href="https://hersenletselliga.be/over-nah/leven-met-nah/terug-aan-het-werk/" target='_blank'>Jobcoach</a></strong> kan samen met de persoon met een hersenletsel bekijken of terug aan het werk gaan mogelijk is. <strong>Samen bespreken</strong> ze dan welke job de persoon nog wil of kan doen en onder welke vorm.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default Home;
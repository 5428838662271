import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@mui/material/Alert';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import brain from "../img/brain.svg";
import facebook from '../img/facebooklogo.png';
import link from '../img/link.png';
import whatsapp from '../img/whatsapp.png';
import { serverIP } from "../index";
import DonationForm from "./Donation/donationForm";
import EditEventForm from './EditEventForm'; // Import the EditEventForm component
import './detail.css';
import { MdEdit, MdDelete } from "react-icons/md";

const Detail = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [role, setRole] = useState();
    const eventid = window.location.hash.split("/").pop();
    const [event, setEvent] = useState(null);
    const [url, setUrl] = useState();
    const [showDoneer, setShowDoneer] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false); // State for showing the edit form

    // Create a ref for the edit form section
    const editFormRef = useRef(null);

    const getUserRoles = async () => {
        setRole(user["http://localhost/roles"]?.includes("Admin"));
    };

    const fetchEvent = async () => {
        try {
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/` + eventid);
            const body = await response.json();
            setEvent(body);
        } catch (e) {
            console.log(e);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const deleteEvent = async () => {
        const confirmDelete = window.confirm('Ben je zeker dat je dit event wilt verwijderen?');
    
        if (!confirmDelete) {
            return; // If the user clicks "Nee", exit the function
        }
    
        try {
            //console.log("voor AccessTokenSilently()");
            const token = await getAccessTokenSilently();
            //console.log("na AccessTokenSilently()");
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/` + eventid, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            //console.log("this is the response: ", response);
    
            if (response.ok) {
                alert('Event successfully deleted!');
                // Redirect to the main events page
                window.location.href = 'https://gift.hersenletselliga.be/#/events';
            } else {
                alert('Failed to delete the event.');
            }
        } catch (error) {
            console.error('Error deleting the event:', error);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(document.location.href).then(() => {
            alert('Link copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    const firstPhotoURL = event ? `${window.location.protocol}//${serverIP}/${event.id}/files/${event.fotoPath[0]}` : "";

    const donateFormRef = useRef(null);

    const handleClickOutside = (event) => {
        if (donateFormRef.current && !donateFormRef.current.contains(event.target)) {
            setShowDoneer(false);
        }
    };

    useEffect(() => {
        if (showDoneer) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDoneer]);

    useEffect(() => {
        if (isAuthenticated) {
            getUserRoles();
        }
        fetchEvent();
    }, [url, user, role, isAuthenticated]);

    const handleEditButtonClick = () => {
        setShowEditForm(true);
        setTimeout(() => {
            if (editFormRef.current) {
                editFormRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end", // Scroll to the end of the viewport
                });
            }
        }, 0); // Ensure scroll occurs after render
    };

    return (
        <div className="detail-container">
            {event ? (
                event.accepted || role ? (
                    <>
                        <div className="header-container">
                            <h1 className="event-title">{event.eventNaam}</h1>
                            <div className="edit-delete-container">
                                {isAuthenticated && user.sub === event.userSub && (
                                    <button className="edit-button" onClick={handleEditButtonClick}>
                                        <MdEdit />
                                    </button>
                                )}
                                {role && (
                                    <button className="delete-button" onClick={deleteEvent}>
                                        <MdDelete />
                                    </button>
                                )}
                            </div>
                        </div>
                        
                        <div className='detail-header-container'>
                            <div className="detail-image-container">
                                <img src={firstPhotoURL} alt="event-image" />
                            </div>
                            <div className='detail-header-text'>
                                <div className="amount-raised">
                                    <div className="event-image-small-container">
                                        <ProgressBar goal={event.doel} current={event.totaalBedrag} />
                                    </div>
                                    <div className="raised-container">
                                        <p className="raised">{`€${event.totaalBedrag}`} / €{event.doel}</p>
                                    </div>
                                </div>

                                <p className="organizer">Georganiseerd door <strong>{event.organisator}</strong></p>
                                <p className="event-date">
                                    Datum: {event.startDatum === event.eindDatum ? formatDate(event.startDatum) : `${formatDate(event.startDatum)} - ${formatDate(event.eindDatum)}`}
                                </p>

                                <div className='detail-header-buttons'>
                                    <button className='donate-button' onClick={() => setShowDoneer(true)}>Doneer</button>
                                    {showDoneer && (
                                        <div className='donate-form-container'>
                                            <div ref={donateFormRef}>
                                                <DonationForm eventid={eventid} maandelijks={false} page={"events"} />
                                            </div>
                                        </div>
                                    )}

                                    <div className='share-container'>
                                        <button className="share-button">
                                            <div className='deel'>Deel</div>
                                            <div className="share-options-container">
                                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.location.href)}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={facebook} alt="Facebook" />
                                                </a>
                                                <a href={`whatsapp://send?text=${encodeURIComponent(document.location.href)}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={whatsapp} alt="WhatsApp" />
                                                </a>
                                                <button onClick={copyToClipboard}>
                                                    <img src={link} alt="Copy link" />
                                                </button>
                                            </div>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="left-content">
                                <div className='inception-button-container'>
                                    <div className="button-container">
                                        <div className='info-title'>Info</div>
                                    </div>
                                    <div className="description">
                                        {event && (
                                            <div>
                                                <p>{event.omschrijving || 'Geen informatie beschikbaar.'}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="image-gallery">
                                    <p>Afbeeldingen:</p>
                                    {event.fotoPath.map((path, index) => (
                                        <img
                                            key={index}
                                            src={`${window.location.protocol}//${serverIP}/${event.id}/files/${path}`}
                                            alt={`Event ${index + 1}`}
                                            style={{ width: '100px', height: '100px', margin: '5px', objectFit: 'cover', cursor: 'pointer' }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="right-content">
                                <div className="donations-list">
                                    <h3>Donaties</h3>
                                    <div className="donations-list-container">
                                        {event.donations && event.donations.length > 0 ? (
                                            event.donations.slice().reverse().map((donation, index) => (
                                                <div key={index} className="donation-item">
                                                    <span>Gedoneerd door: {donation.showName ? donation.showName : 'Anoniem'}</span>
                                                    <span> €{donation.amount}</span>
                                                </div>
                                            ))
                                        ) : (
                                            <p>Voor dit event zijn er nog geen donaties.</p>
                                        )}
                                    </div>
                                </div>
                                <div className="top-donations-list">
                                    <h3>Top donaties</h3>
                                    <div className="donations-list-container">
                                        {event.donations && event.donations.length > 0 ? (
                                            event.donations
                                                .slice() // Create a copy of the array to avoid mutating the original
                                                .sort((a, b) => b.amount - a.amount) // Sort donations in descending order by amount
                                                .map((donation, index) => (
                                                    <div key={index} className="donation-item">
                                                        <span>Gedoneerd door: {donation.showName ? donation.showName : 'Anoniem'}</span>
                                                        <span>{formatDate(donation.donationDate)}</span>
                                                        <span> €{donation.amount}</span>
                                                    </div>
                                                ))
                                        ) : (
                                            <p>Voor dit event zijn er nog geen donaties.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Add ref to this div to scroll to it */}
                        <div ref={editFormRef}>
                            {showEditForm && (
                                <EditEventForm event={event} onClose={() => setShowEditForm(false)} />
                            )}
                        </div>
                    </>
                ) : (
                    <div className='rejectedEvent'> Dit event is nog niet geaccepteerd</div>
                )
            ) : (
                <div>Loading event details...</div>
            )}
        </div>
    );
}

const ProgressBar = ({ goal, current }) => {
    let completion = (current / goal) * 100;
    let x = 0;
    if (completion > 100) {
        let n = current;
        n = n - goal;
        while (n > 0) {
            n = n - goal;
            x++;
        }
        completion = 100;
    }
    return (
        <svg width="100%" height="100%" viewBox="-50 -150 500 500" preserveAspectRatio="xMidYMid meet">
            <defs>
                {completion > 0 && (
                    <filter id="orange-fill">
                        <feFlood floodColor="orange" />
                        <feOffset dy={200 - (completion / 100) * 200}>
                            <animate attributeName="dy" from={200} to={200 - (completion / 100) * 200} dur="2s" />
                        </feOffset>
                        <feComposite operator="in" in2="SourceGraphic" />
                        <feComposite operator="over" in2="SourceGraphic" />
                    </filter>
                )}
            </defs>

            <image filter={completion > 0 ? "url(#orange-fill)" : ""} xlinkHref={brain} height={200} width={400} />

        </svg>
    );
};

export default Detail;
import React, { useState, useEffect } from 'react';
import '../pages/Footer.css';



const Footer = () => {

  return (
    <footer className='footer'>
        <div className='left-part'>
      <p>© 2023 Hersenletsel Liga VZW | alle rechten voorbehouden</p>
      </div>
      <div className='right-part'>
      <nav>
        <ul>
          <li><a href="https://hersenletselliga.be/statuten/">Statuten</a></li>
          <li><a href="https://hersenletselliga.be/privacybeleid/">Privacybeleid</a></li>
          <li><a href="https://hersenletselliga.be/cookiebeleid/">Cookiebeleid</a></li>
        </ul>
      </nav>
      </div>    
    </footer>
  );
};

export default Footer;

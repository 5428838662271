import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import check from "../img/check.gif";

class ConfirmationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentStatus: null,
            redirect: false,
            eventid: null
        };
    }

    componentDidMount() {
        this.fetchPaymentStatus();
    }

    fetchPaymentStatus = async () => {
        
        const eventid = sessionStorage.getItem('eventid')
        sessionStorage.removeItem('eventid')
        this.setState({eventid})
    };

    componentWillUnmount() {
        clearTimeout(this.id); 
    }

    render() {
        

        const { redirect, eventid} = this.state;
    
            setTimeout(() => this.setState({redirect: true}), 5000);
            return (
                
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center', 
                    height: ' 90vh',
                }}
                >
                <img src={check} alt="check" width={100} height={100}/>
                <div style={{ marginTop: '20px' }} > <h1> Bedankt! Dankzij jouw gift kunnen we onze werking verderzetten en de levenskwaliteit van personen met een niet-aangeboren hersenletsel en hun naasten ondersteunenverbeteren. Elke gift helpt!  </h1> </div>
                <div>
                {eventid ? (
                                                redirect && (<Navigate to={`/detail/${eventid}`}/>)
                                                ) : (
                                                redirect && (<Navigate to={`/events`}/>)
                                                )}
                </div>
                </div>
            );
    }
}

export default ConfirmationPage;